import {Button} from "antd";
import React from "react";
import {SubmitContactMeGoogleForm} from "./SubmitContactMeGoogleForm";

const ContactMeContainer = () => {
	const handleEmailMessage = (e: any) => {
		window.location.href = "mailto:coawazie@gmail.com";
		e.preventDefault();
	}
	
	return (
		<div className="flex flex-col items-center">
			<h1
				className="flex text-lg md:text-3xl mb-2 md:mb-5 font-semibold text-green-900 items-center">
				Contact Me</h1>
			<div
				className="flex flex-col  md:flex-row md:w-4/5  justify-center  mt-2 md:mt-8 md:mb-5  md:gap-[1vw]">
				<div className="me:w-2/5">
					<h2
						className="text-md md:text-2xl mb-2 md:mb-1 font-semibold text-green-900 items-center">
						Mailing Address:
					</h2>
					<p className="text-slate-600 font-bold  ">Chinomnso Awazie</p>
					<p className="text-slate-600  ">BackHome Technologies LLC</p>
					<p className="text-slate-600  ">90 Church Street</p>
					<p className="text-slate-600 ">Unit 3265</p>
					<p className="text-slate-600 ">New York, NY 10008</p>
					<div className="flex mt-2">
						<p className="text-slate-600 font-semibold mr-2 ">Phone:</p>
						<p className="text-slate-600 ">+1 718 340-8969</p>
					</div>
					<Button onClick={handleEmailMessage}
					        className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100">
						Email me
					</Button>
				
				</div>
				<div className=" md:w-4/5">
					<SubmitContactMeGoogleForm/>
				</div>
			</div>
		</div>
	)
}

export {ContactMeContainer}
