import React from 'react';
import {Provider} from 'react-redux';
import MainContainer from './containers/MainContainer';
import {UserLocaleProvider} from './providers/UserLocale/UserLocale';
import {coreStore} from "./store";

type AppProps = {
	props?: any;
};
const App: React.FunctionComponent<AppProps> = ({props}: AppProps) => {
	const locale = 'en-Us';
	const localeCopy = props?.localeMessages;
	return (
		<Provider store={coreStore}>
			<UserLocaleProvider locale={locale} defaultLocale="en" localeCopy={localeCopy}>
				<MainContainer/>
			</UserLocaleProvider>
		</Provider>
	)
	
}

export default App;
