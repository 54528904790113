import {Button, Form, Input, notification} from 'antd';
import React, {useCallback} from 'react';

const {TextArea} = Input;


const SubmitContactMeGoogleForm = () => {
	const [submitted, setSubmitted] = React.useState(false);
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	const onFinish = useCallback(
		async ({email, feedbackText, fullName, phoneNumber}: {
			email: string;
			feedbackText: string
			fullName: string;
			phoneNumber: string;
		}) => {
			try {
				await fetch(
					'https://docs.google.com/forms/d/e/1FAIpQLSfo4Uimr2QS_ur_mibDVIuKeq1xgAiX0esx7WdUwrXytZlB-g/formResponse?'
					+ new URLSearchParams({
						'entry.149879571': fullName,
						'entry.2092943597': feedbackText,
						'entry.287146971': phoneNumber,
						'entry.1116328328': email
						
					}),
					{
						mode: 'no-cors'
					}
				);
				api.success({
					message: 'Submitted successfully'
				})
				setTimeout(() => {
					setSubmitted(true);
				}, 2000)
				
			} catch (e: any) {
				api.error({
					message: e.message
				});
			}
		},
		[api]
	);
	
	return (
		<>
			{!submitted && <h2
				className="flex text-md md:text-2xl mb-2 md:mb-1 font-semibold text-green-900 items-center">
				Contact Form
			</h2>}
			{contextHolder}
			{submitted && <p className="text-gray-600 md:text-xl">
				Thank you again for your feedback. I will reach out to you if needed.</p>}
			{!submitted && <>
				<p className="text-gray-800 mb-1 md:mb-1"><span className="text-amber-700">*</span> Indicates required
					field</p>
				<Form
					form={form}
					layout="vertical"
					wrapperCol={{span: 18}}
					onFinish={onFinish}
				>
					<Form.Item
						name="fullName"
						label="Full Name"
						className="font-semibold text-gray-800"
						rules={[{required: true, message: 'Name  is required'}]}
					>
						<Input placeholder={'eg. Obinna Nwafo'}/>
					</Form.Item>
					<Form.Item
						name="phoneNumber"
						label="Phone Number"
						className="font-semibold text-gray-800"
						rules={[{required: true, message: 'Phone number  is required'}]}
					>
						<Input placeholder={'eg. 234-456-7890'}/>
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						className="font-semibold text-gray-800"
						rules={[{required: true, message: 'Email address is required'}]}
					>
						<Input placeholder={'eg. someone@example.com'}/>
					</Form.Item>
					
					<Form.Item
						name="feedbackText"
						className="font-semibold text-gray-800"
						
						label="Message"
						rules={[{required: true, message: 'Your response is required'}]}
					>
						<TextArea
							rows={4}
							showCount
							className="font-normal text-gray-800 "
							placeholder={'Type in your message here'}
							maxLength={1000}/>
					</Form.Item>
					
					<Button className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100"
							type="primary" htmlType="submit">
						Submit
					</Button>
				</Form>
			</>
			}
		</>
	);
};

export {SubmitContactMeGoogleForm};
