import React from "react";
import {useIntl} from 'react-intl';
import {useNavigate} from "react-router-dom";
import ChevronDownIcon from '../../../Images/TriangleDownIcon.png'
import {headerMessages} from '../../../messages';
import {NewsAndBlog} from "../../../utils/header/NewsAndBlog";


const HeaderTabsItemsDesktop = () => {
	const {formatMessage} = useIntl();
	const navigate = useNavigate();
	const [states, setStates] = React.useState({
		showNews: false
		
	});
	
	const {showNews} = states
	const closeDropDowns = () => {
		setStates((e) => ({
			...e,
			showNews: false
		}));
	};
	const portfolioButtonClick = () => {
		navigate('/portfolio')
	}
	const aboutButtonClick = () => {
		navigate('/about')
	}
	const blogButtonClick = () => {
		navigate('/blog')
	}
	const homeButtonClick = () => {
		navigate('/')
	}
	const newsButtonClick = (buttonClicked: string) => {
		navigate(buttonClicked)
	};
	
	const commonButtonClasses = 'flex py-1 md:mx-10  md:py-2 px-1 md:px-3  rounded-md text-xs md:text-sm md:font-medium  transform hover:scale-110 focus:outline-none'
	const highlighted = 'bg-amber-600  text-gray-900'
	const blurred = 'bg-gray-600  text-gray-300'
	
	return (
		<div
			className="md:ml-10 flex items-center justify-center space-x-1 md:space-x-4"
			onMouseLeave={closeDropDowns}
		>
			<div>
				
				<button
					className={
						`${window.location.pathname === '/home' || window.location.pathname === '/' ? `${highlighted}`
							: `${blurred}`}  ${commonButtonClasses} ml-2 md:ml-4`
					}
					onClick={homeButtonClick}
				>
					{formatMessage(headerMessages.home)}
				</button>
			</div>
			
			<div>
				<button
					className={
						`${window.location.pathname === '/about' ? `${highlighted}`
							: `${blurred}`}  ${commonButtonClasses}  `
					}
					onClick={aboutButtonClick}
				>
					{formatMessage(headerMessages.about)}
				</button>
			
			</div>
			
			
			<div>
				
				<button
					className={
						`${window.location.pathname === '/portfolio' ? `${highlighted}`
							: `${blurred}`}  ${commonButtonClasses} md:mr-10  `
					}
					onClick={portfolioButtonClick}
				>
					{formatMessage(headerMessages.portfolio)}
				</button>
			</div>
			
			<div>
				<button
					className={
						`${window.location.pathname === '/blog' ? highlighted
							: blurred}  ${commonButtonClasses}  `
					}
					onClick={blogButtonClick}
				>
					{formatMessage(headerMessages.awazieBlog)}
				</button>
			
			</div>
			
			<div className="flex flex-row transform hover:scale-110  ">
				<button
					className={`${(window.location.pathname === '/in-the-news' || window.location.pathname
						=== '/press-releases') ? `${highlighted}`
						: `${blurred}`} flex py-1 md:ml-10 md:py-2 px-1 md:px-3  rounded-md text-xs md:text-sm md:font-medium focus:outline-none`}
					onClick={() => setStates((e) => ({
						...e,
						showNews: !showNews
					}))}
				>
					{formatMessage(headerMessages.news)}
				</button>
				<img
					className={`mt-2 md:mt-3 ml-0.5 md:mr-10 h-2 md:h-3 w-2 md:w-5`}
					src={ChevronDownIcon} alt="Logo"
					onClick={() => setStates((e) => ({
						...e,
						showNews: !showNews
					}))}
				/>
				
				{showNews && (
					<NewsAndBlog buttonClicks={newsButtonClick}/>
				)}
			
			</div>
		
		
		</div>
	);
};
export {HeaderTabsItemsDesktop};
