import React from "react";
import GreenSuccessIcon from "../Images/GreenSuccessIcon.png";
import InfoIcon from "../Images/InfoIcon.png";
import MapsTabScreen from "../Images/MapsTabScreen.png";
import MapViewIcon from "../Images/MapViewIcon.png";
import MemberIcon from "../Images/MemberIcon.png";
import SearchIcon from "../Images/SearchIcon.png";
import StationMap from "../Images/StationMap.png";
import StationsCardList from "../Images/StationsCardList.png";
import UserProfile from "../Images/UserProfile.png";

type NewsBodyProps = {
	children?: React.ReactNode;
};

const AwaziePortfolio: React.FunctionComponent<NewsBodyProps> = ({
	                                                                 children
                                                                 }: NewsBodyProps) => {
	const listIcons = 'h-3 md:h-6 w-3 md:w-6  mx-0.5 md:mx-2 mr-1'
	const listContainer = 'flex rounded md:rounded-xl   flex-row mt-1 px-0.5 py-1 bg-gradient-to-r '
	
	
	return (
		<div>
			<main>
				{children}
				<div className="max-w-7xl mx-auto py-6   min-h-[309vh] max-h-[4000vh] sm:px-6 lg:px-8">
					<div className="px-4 py-6 sm:px-0">
						
						<p className=" md:text-3xl md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-green-900">
							I have been working on a few projects. Some are in beta testing, while some are in
							AppStore.
						</p>
						<p className=" md:text-3xl md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-green-900">
							Below is one of them.
						</p>
						<div>
							<p className="text-2xl md:text-6xl md:mt-10 md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-green-900">
								Station Locate App
							</p>
							<p className="md:text-3xl py-2 pr-2 md:py-11 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
								This app makes it easy to find the nearest charging station no matter where you
								are.
								With features like map directions, and user reviews, you can be sure
								you'll always find a reliable charging station when you need it. </p>
						
						</div>
						
						<div className="md:flex md:ml-2 md:flex-col sm:px-0">
							<div
								className="flex flex-row items-center justify-center  mt-4 mx-8 md:mx-0 sm:px-0">
								
								<div className="w-1/3">
									<img className="h-[20vh] md:h-[38vh]  " src={StationsCardList} alt="Logo"/>
								</div>
								<div className="  w-1/3">
									<img className="h-[20vh] md:h-[38vh]  " src={StationMap} alt="Logo"/>
								</div>
								<div className="w-1/3 md:w-1/3">
									<img className="h-[20vh] md:h-[38vh] w-[38vw] md:w-[38vw] mr-1"
									     src={MapsTabScreen}
									     alt="Logo"/>
								
								</div>
							</div>
							<div className="flex flex-row sm:px-0">
								
								<div className="ml-2 md:ml-6 mr-1">
									<ul className="mt-2 p-3 text-xs md:text-lg text-white ">
										<li className={`${listContainer} items-center  from-green-800 to-indigo-400 `}>
											<img className={`${listIcons}`} src={SearchIcon} alt="Logo"/>
											<p>Find stations nearby, or a different address anywhere in North
												America</p>
										</li>
										<li className={`${listContainer} items-center  from-sky-400 to-indigo-400`}>
											<img className={`${listIcons}`} src={MapViewIcon} alt="Logo"/>
											<p>View results on a map, or as a list, your choice!</p>
										</li>
										<li className={`${listContainer} items-center from-violet-400 to-fuchsia-400`}>
											<img className={`${listIcons}`}
											     src={InfoIcon}
											     alt="Logo"/>
											<p>View detailed information about a chosen station, call the
												location or
												provider for more information</p>
										</li>
										<li className={`${listContainer} from-green-800 to-fuchsia-400`}>
											<img className={`${listIcons} mt-3`} src={MemberIcon} alt="Logo"/>
											<div>
												<p>Become a member for more features like </p>
												<ul className="ml-5 md:text-sm ">
													<li className=" flex flex-row mt-1 items-center ">
														<img className="h-3 w-3 mr-2 " src={GreenSuccessIcon}
														     alt="Logo"/>
														Save locations (home, office, etc) for quick
														searches
													</li>
													<li className=" flex flex-row mt-1 items-center ">
														<img className="h-3 w-3 mr-2 " src={GreenSuccessIcon}
														     alt="Logo"/>
														See a station you like? Add it to your favorites!
													</li>
													<li className=" flex flex-row mt-1 items-center ">
														<img className="h-3 w-3 mr-2 " src={GreenSuccessIcon}
														     alt="Logo"/>
														Add your car**
													</li>
													<li className=" flex flex-row mt-1 items-center ">
														<img className="h-3 w-3 mr-2 " src={GreenSuccessIcon}
														     alt="Logo"/>
														Get helped from highly ranked members
													</li>
													<li className=" flex flex-row mt-1 items-center ">
														<img className="h-3 w-3 mr-2 " src={GreenSuccessIcon}
														     alt="Logo"/>
														User reviews
													</li>
													<li className=" flex flex-row mt-1 items-center ">
														<img className="h-3 w-3 mr-2 " src={GreenSuccessIcon}
														     alt="Logo"/>
														Being part of a pioneer community
													</li>
												</ul>
											</div>
										</li>
									
									</ul>
								</div>
								<div>
									<img className="h-[32vh] w-[32vw] hidden md:block mr-1" src={UserProfile}
									     alt="Logo"/>
								</div>
							</div>
						</div>
						
						<div className="md:flex md:ml-2 md:flex-col sm:px-0  items-center justify-center">
							
							<div
								className=" flex  md:mt-10 align-baseline  items-center justify-center flex-row ">
								
								<p className="text-2xl font-bold text-gray-500 md:text-3xl md:mt-10 mr-1 md:mr-4 ">App
									Website: </p>
								
								<a href="https://stationlocate.com"
								   target="_blank"
								   rel="noreferrer"
								   className="text-3xl md:mt-10 text-indigo-400 hover:text-indigo-500"
								
								>
									stationlocate.com</a>
							</div>
							<div
								className=" flex  md:mt-2  items-center justify-center flex-row ">
								<a href="https://apps.apple.com/us/app/station-locate/id6469472521?itsct=apps_box_badge&amp;itscg=30200"
								   target="_blank"
								   rel="noreferrer"
								
								>
									<img
										className="rounded-xl h-12 md:h-24 w-32 md:w-64"
										src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1697673600"
										alt="Download on the App Store"/></a>
								<a href="https://play.google.com/store/apps/details?id=com.stationlocatemobilev10&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
								   target="_blank"
								   rel="noreferrer"
								>
									<img
										className="rounded-xl h-16 md:h-32 w-36 md:w-72"
										src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
										alt="Get it on Google Play"/></a>
							
							</div>
						
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export {AwaziePortfolio};
