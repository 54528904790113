import React from "react";
import facebook_logo from '../Images/socialMedia/facebook_logo.png';
import github_logo from '../Images/socialMedia/github_logo.png';
import instagram_logo from '../Images/socialMedia/instagram_logo.png';
import LinkedInLogo from '../Images/socialMedia/LinkedInLogo.png';
import twitter_logo from '../Images/socialMedia/twitter_logo.png';
import {EmptyProps, openUrl} from "../utils/app";

const Footer: React.FunctionComponent<EmptyProps> = () => {
	
	return (
		<div className="flex  flex-col w-auto  sticky  bottom-0 z-20">
			<nav className="flex bg-[#065F46]">
				<div
					className="flex  mx-auto px-4 sm:px-6 space-x-4   lg:px-8 h-10"
				>
					<div className="flex flex-row items-center justify-center gap-4">
						<img
							src={github_logo}
							alt="GitHub Logo"
							className="w-8 h-8 cursor-pointer hover:bg-gray-200 rounded-full"
							onClick={() => openUrl('https://github.com/chinomnsoawazie')}
						/>
						<img
							src={LinkedInLogo}
							alt="LinkedIn Logo"
							className="w-8 h-8 cursor-pointer hover:bg-gray-200 rounded-full"
							onClick={() => openUrl(' https://www.linkedin.com/in/chinomnsoawazie')}
						/>
						<img
							src={twitter_logo}
							alt="Twitter Logo"
							className="w-12 h-12 cursor-pointer hover:bg-gray-200 rounded-full"
							onClick={() => openUrl('https://twitter.com/COAwazie')}
						/>
						<img
							src={facebook_logo}
							alt="Facebook Logo"
							className="w-16 h-10 cursor-pointer hover:bg-gray-200 rounded-3xl"
							onClick={() => openUrl('https://www.facebook.com/COAwazie')}
						/>
						<img
							src={instagram_logo}
							alt="Instagram Logo"
							className="w-10 h-6 cursor-pointer hover:bg-gray-200 rounded-full"
							onClick={() => openUrl('https://www.instagram.com/coawazie/')}
						/>
					</div>
				
				
				</div>
			</nav>
		</div>
	);
};

export default Footer;
