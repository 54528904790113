import React from "react";
import NewPic from "../Images/NewPic.jpg";
import {ContactMeContainer} from "../pageComponents/about";


const About = () => {
	
	return (
		<div>
			<main>
				<div className=" ml-2 mr-1  md:mx-20 pb-20  min-h-[109vh]   md:px-6">
					<div className="flex flex-row px-2 md:px-32    sm:px-0">
						<img src={NewPic} className="w-40 h-20  md:w-80 md:h-40 my-5 rounded-2xl "
						     alt="homepage photograph"/>
						<div className="my-5 ml-10">
							<p className="md:text-2xl py-2  md:py-4 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
								I am a fullstack software engineer with over 15 years cognate experience in IT and
								electrical engineering. My entrepreneurial spirit means I am always innovating solutions
								to issues I care about. </p></div>
					
					</div>
					<ContactMeContainer/>
				</div>
			</main>
		</div>
	);
};


export {About};
