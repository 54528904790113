import {useLocation} from 'react-router-dom';
import Layout from '../components/Layout';
import {About, AwazieBlog, AwaziePortfolio, AwaziePressReleases, Home, InTheNews} from '../pages';

const MainContainer = () => {
	const {pathname} = useLocation()
	
	return (
		<>
			{(pathname === '/home' || pathname === '/') && <Layout>
				<Home/>
			</Layout>
			}
			{(pathname === '/in-the-news') && <Layout>
				<InTheNews/>
			</Layout>
			}
			
			{(pathname === '/blog') && <Layout>
				<AwazieBlog/>
			</Layout>
			}
			{(pathname === '/portfolio') && <Layout>
				<AwaziePortfolio/>
			</Layout>
			}
			
			{(pathname === '/press-releases') && <Layout>
				<AwaziePressReleases/>
			</Layout>
			}
			{(pathname === '/about') && <Layout>
				<About/>
			</Layout>
			}
		
		</>
	);
};

export default MainContainer
