import Footer from './Footer';
import Header from './Header/Header';

type LayoutProps = {
	children?: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
	                                                      children
                                                      }: LayoutProps) => {
	return (
		<>
			<Header/>
			<div>{children}</div>
			<Footer/>
		</>
	);
};

export default Layout;
