import {Button} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";
import NewPic from '../Images/NewPic.jpg'
import FacebookPage from "../Images/socialMedia/FacebookPage.png";
import github_logo from "../Images/socialMedia/github_logo.png";
import instagram_logo from "../Images/socialMedia/instagram_logo.png";
import LinkedInLogo from "../Images/socialMedia/LinkedInLogo.png";
import twitter_logo from "../Images/socialMedia/twitter_logo.png";


import {SubmitSubscribeToNewsletterGoogleForm} from "../pageComponents/home";
import {openUrl} from "../utils/app";


type HomeBodyProps = {
	children?: React.ReactNode;
};

const Home: React.FunctionComponent<HomeBodyProps> = ({children}: HomeBodyProps) => {
	const navigate = useNavigate();
	const myName = '< Chinomnso Awazie >'
	const aboutMeTitle = '</ About Me >'
	const handleEmailMessage = (e: any) => {
		window.location.href = "mailto:coawazie@gmail.com";
		e.preventDefault();
	}
	return (
		<div
		
		>
			<main>
				{children}
				<div
					className="flex flex-col max-w-7xl mx-auto py-6  min-h-[109vh] max-h-[4000vh]   sm:px-6 lg:px-8 ">
					<div className="md:grid md:grid-cols-2 px-4 py-6 sm:px-0">
						
						<div className="flex flex-col justify-center items-center">
							<img src={NewPic} className="w-160 h-80 my-5 rounded-2xl " alt="homepage photograph"/>
							
							<p className="text-2xl pt-2 md:pt-12 md:text-3xl antialiased font-semibold text-green-900">
								{myName}
							</p>
							<p className="text-2xl md:text-xl md:pt-4 antialiased font-semibold text-green-900">
								Software Engineer (he/him)
							</p>
							
							<Button onClick={handleEmailMessage}
							        className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100">
								Email me
							</Button>
							
							<div className="flex mt-10 flex-row items-center justify-center gap-4">
								<img
									src={github_logo}
									alt="GitHub Logo"
									className="w-1/12 h-1/12 cursor-pointer hover:bg-gray-200 rounded-full"
									onClick={() => openUrl('https://github.com/chinomnsoawazie')}
								/>
								
								<img
									src={LinkedInLogo}
									alt="LinkedIn Logo"
									className="w-1/12 h-1/12 cursor-pointer hover:bg-gray-200 rounded-full"
									onClick={() => openUrl(' https://www.linkedin.com/in/chinomnsoawazie')}
								/>
								
								<img
									src={twitter_logo}
									alt="Twitter Logo"
									className="w-2/12 h-2/12 cursor-pointer hover:bg-gray-200 rounded-full"
									onClick={() => openUrl('https://twitter.com/COAwazie')}
								/>
								<img
									src={FacebookPage}
									alt="Facebook Logo"
									className="w-1/12 h-1/12    cursor-pointer hover:bg-gray-200 rounded-50"
									onClick={() => openUrl('https://www.facebook.com/COAwazie')}
								/>
								
								<img
									src={instagram_logo}
									alt="Instagram Logo"
									className="w-2/12 h-2/12 cursor-pointer hover:bg-gray-200 rounded-full"
									onClick={() => openUrl('https://www.instagram.com/coawazie/')}
								/>
							</div>
						
						</div>
						
						<div className=" flex flex-col  items-center">
							<h2 className="my-5 text-2xl  md:text-3xl antialiased font-semibold text-green-900">{aboutMeTitle}</h2>
							<p className="md:text-2xl py-2  md:py-4 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
								I am a fullstack software engineer with over 15 years cognate experience in IT and
								electrical engineering. My entrepreneurial spirit means I am always innovating solutions
								to issues I care about. </p>
							
							<div className="flex gap-6">
								<Button onClick={() => navigate('/about')}
								        className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100">
									More about me
								</Button>
								<Button onClick={() => navigate('/portfolio')}
								        className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100">
									My Portfolio
								</Button>
							</div>
						
						</div>
					
					
					</div>
					<div className="flex items-center justify-center flex-col  mb-10  md:mt-[10vh]">
						<SubmitSubscribeToNewsletterGoogleForm/>
					
					</div>
				
				
				</div>
			</main>
		</div>
	);
};
export default Home;
