const openUrl = (urlToOpen: string) => {
	const popup_window = window.open(urlToOpen);
	try {
		popup_window?.focus();
	} catch (e) {
		alert('Please disable your pop-up blocker and click the "Open" link again.');
	}
};

export  {openUrl};
