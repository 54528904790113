import React from "react";
import {HeaderTabsItemsDesktop} from "./headerItems";

const Header = () => {
	return (
		<div className="flex flex-col w-auto sticky  top-0 z-10">
			<nav className="bg-[#065F46]">
				<div className=" mx-1 md:mx-auto px-0 md:px-4  lg:px-8">
					<div className="flex  justify-center h-16 md:mr-2">
						<HeaderTabsItemsDesktop/>
					</div>
				</div>
			</nav>
		</div>
	);
};

export default Header;
