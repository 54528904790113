import React, {createContext, ReactNode, useContext} from 'react';
import {IntlProvider} from 'react-intl';
import {Locale} from './getLocale';

const DEFAULT_LOCALE = 'en-US';
type UserLocaleProviderProps = {
    locale: string;
    children: ReactNode;
    defaultLocale: string | undefined;
    localeCopy: Locale;
};

type UserLocaleContextType = {
    locale: string;
};

const UserLocaleContext = createContext<UserLocaleContextType>({
                                                                   locale: DEFAULT_LOCALE
                                                               });

export const UserLocaleProvider=
    ({
         locale,
         children,
         defaultLocale,
         localeCopy
     }: UserLocaleProviderProps) => {
        return (
            <UserLocaleContext.Provider value={{locale}}>
                <IntlProvider
                    locale={locale}
                    defaultLocale={defaultLocale}
                    messages={localeCopy}
                    onError={(err) => {
                    }}
                >
                    {children}
                </IntlProvider>
            </UserLocaleContext.Provider>
        );
    };

export const useUserLocale = () => useContext(UserLocaleContext);
