import React from "react";
import {useIntl} from 'react-intl';
import {headerMessages} from "../../messages";

type NewsAndBlogsProps = {
	buttonClicks: (buttonClicked: string) => void;
};
const NewsAndBlog: React.FunctionComponent<NewsAndBlogsProps> = ({
	                                                                 buttonClicks
                                                                 }: NewsAndBlogsProps) => {
	const {formatMessage} = useIntl();
	return (
		<div
			className={"origin-bottom-left mt-2 md:mt-9 absolute -ml-5 md:ml-0  w-28   md:w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"}
			role="menu"
			aria-orientation="vertical"
			aria-labelledby="news-menu-button"
			tabIndex={-1}
		>
			
			<button
				className="flex pl-[0.2rem]  md:px-4 py-1 md:py-2 md:w-full text-xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
				type="button"
				onClick={() => {
					buttonClicks('/in-the-news');
				}}
			>
				{formatMessage(headerMessages.awazieNews)}
			</button>
			<button
				className="flex pl-[0.2rem]  md:px-4 py-1 md:py-2 md:w-full text-xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
				type="button"
				onClick={() => {
					buttonClicks('/press-releases');
				}}
			>
				{formatMessage(headerMessages.awaziePressReleases)}
			</button>
		
		
		</div>
	);
};

export {NewsAndBlog};
